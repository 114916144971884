import React from "react";
import { Grid } from "@mui/material";
import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles({
  title:{
    fontFamily:"Montserrat",
    fontSize: "14px",
    fontWeight: "700",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "0.49px",
    textAlign: "left",
    textTransform:"uppercase",
    marginBottom: "16px",
    color: "#948771",
  },
  linkText:{
    fontFamily:"Montserrat",
    fontSize: "15px",
    fontWeight: "400",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.2,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#ffffff",
    cursor:"pointer",
    "&:hover":{
      fontWeight: "bold",
      textDecoration: "underline"
    },
  }
});

export const FooterText = ({ text, itemList }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      display={"flex"}
      flexDirection={"column"}
      marginBottom={"20px"}
    >
      <Grid item>
        <Typography className={classes.title}>
          {text}
        </Typography>
      </Grid>

      <Grid item>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
            width:'auto'
          }}
        >
          {itemList.map((item) => {
            return (
              <Typography
                key={item?.name}
                className={classes.linkText}
                onClick={() => {
                  window.open(item.link);
                }}
              >
                {item?.name}
              </Typography>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};
