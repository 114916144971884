import React from "react";
import { FailDataBreach } from "./FailDataBreach";
import { FailDataBroker } from "./FailDataBroker";
import styles from './styles';

export const FailResponseContainer = ({
  hasDataBrokerExposure,
  profiles,
  sites,
  hasDataBreachExposure,
  dataBreaches,
  dataBreachesSites,
}) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <img
        height={150}
        width={150}
        src="/FailLogo.png"
        alt="Fail Logo"
      />
      {hasDataBrokerExposure ? (
        <FailDataBroker profiles={profiles} sites={sites} />
      ) : null}
      {hasDataBreachExposure ? (
        <FailDataBreach
          dataBreaches={dataBreaches}
          dataBreachesSites={dataBreachesSites}
        />
      ) : null}
    </div>
  );
};
