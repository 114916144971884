import React from "react";
import { Button } from "@mui/material";

export const CustomButton = ({ title, onClick, customWidth }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        borderRadius: 0,
        bgcolor: "#948771",
        opacity: 1,
        width: customWidth,
      }}
    >
      {title}
    </Button>
  );
};
