import {makeStyles} from "@material-ui/core";

const styles = makeStyles({
    container: {
        display:"flex",
        flexDirection:"column",
        alignItems: "center",
        backgroundImage: "url('/iStock-1298696948.png')",
        backgroundRepeat: "no-repeat",
        backgroundPositionX:"left, 25%",
    },
    title:{
        fontFamily:"Montserrat",
        fontSize: "40px",
        marginTop: "190px",
        marginBottom: "28px",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#fff",
    },
    description:{
        fontFamily:"Open Sans",
        fontSize: "18px",
        lineHeight: 1.57,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#fff",
    },
    footprintIcon: {
      marginBottom: "-10px"
    },
    quickSearchText: {
        fontFamily:"Montserrat",
        fontSize: "14px",
        fontWeight: "bold",
        textTransform:"uppercase",
        marginTop: 28,
        lineHeight: 1.71,
        letterSpacing: 0.49,
        textAlign: "left",
        color: "#76b7c7",
    },
    phoneImg: {
        maxWidth: 585,
        "@media only screen and (max-width: 1556px)":{
            display: "none"
        }
    },
    explanationContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent:"space-between",
        width: "100vw",
        "@media only screen and (max-width: 1556px)":{
            width: "100%"
        },
        marginTop: 66
    },
    textContainer: {
        maxWidth: 678,
        marginLeft: 215,
        "@media only screen and (max-width: 1556px)":{
            marginLeft: 0
        }
    },
    formContainer: {
        marginTop: 87,
        marginBottom: 160,
        maxWidth: 770
    },
    formTitle: {
        fontFamily: "Montserrat",
        fontSize: 20,
        fontWeight: "bold",
        color: "#948771",
        lineHeight: 1.65
    }
});

export default styles;