import React, {useState} from "react";
import {Grid, Typography} from "@material-ui/core";
import {description, firstPageFormTitle, firstPageFormTitle2, firstTitle} from "../utils/constants";
import {InputForm} from "../Components/InputForm";
import {CustomButton} from "../Components/CustomButton";
import {FootprintIcon} from "../Components/Icons/Footprint";
import { useSnackbar } from 'notistack';
import {validationSchema} from "./validationSchema";
import validate from "validate.js";
import styles from './styles';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";

const INITIAL_STATE = {
    isValid: false,
    values: {
        firstName: "",
        lastName: "",
        zipCode: "",
        email: ""
    },
    touched: {},
    errors: {},
};

validate.options = {
    fullMessages: false,
};

const LandingPage = ({setLoading, setFirstPage, setResponse, googleReCaptchaProps}) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();

    const [formState, setFormState] = useState(INITIAL_STATE);

    const hasError = (field) => formState.errors[field] ? true : false;

    const handleChange = (event) => {
        event.persist();
        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const submitVerification = async () => {
        const errors = validate(formState.values, validationSchema);
        if (errors) {
            setFormState((formState) => ({
                ...formState,
                isValid: true,
                errors: errors,
            }));
            return;
        }

        window.scrollTo(0, 0)

        setLoading(true);
        setFirstPage(false);

        const result = await googleReCaptchaProps.executeRecaptcha("submit");

        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                token: result, firstName: formState.values.firstName, lastName: formState.values.lastName,
                zipCode: formState.values.zipCode, email: formState.values.email,
            }),
        };

        let endpoint =  '/hasBrokerExposureAndBreachData'

        fetch(endpoint, requestOptions).then((response) => {
            response.json()
                .then(async (body) => {

                setResponse(body);
                setLoading(false);
            })
                .catch(() => {
                    enqueueSnackbar(
                        "There was an error processing your request. Please try again later.",
                        {variant: 'error'});
                    setLoading(false);
                    setFirstPage(true);
                });
        });
    };
    return <div className={classes.container}>
        <div className={classes.explanationContainer}>
            <div className={classes.textContainer}>
                <Typography className={classes.title}>
                    {firstTitle}
                </Typography>
                <Typography className={classes.description}>
                    {description}
                </Typography>
                <Typography className={classes.quickSearchText}>
                    <FootprintIcon/> Run a quick search now and see your results in just seconds
                </Typography>
            </div>
            <img className={classes.phoneImg} src="/bg2.png" alt={"PhoneImg"}/>
        </div>
        <div className={classes.formContainer}>
            <Grid
                container
                spacing={4}
            >
                <Grid item xs={12}>
                    <Typography className={classes.formTitle}>
                        {firstPageFormTitle}
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <InputForm
                        title="First name"
                        mandatory={true}
                        name="firstName"
                        value={formState.values.firstName || ""}
                        error={hasError("firstName")}
                        helperText={hasError("firstName") ? formState.errors.firstName[0] : null}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <InputForm
                        title="Last name"
                        mandatory={true}
                        name="lastName"
                        value={formState.values.lastName || ""}
                        error={hasError("lastName")}
                        helperText={hasError("lastName") ? formState.errors.lastName[0] : null}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputForm
                        title="Zip code"
                        mandatory={true}
                        name="zipCode"
                        value={formState.values.zipCode || ""}
                        error={hasError("zipCode")}
                        helperText={hasError("zipCode") ? formState.errors.zipCode[0] : null}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.formTitle}>
                        {firstPageFormTitle2}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputForm
                        title="Email address"
                        mandatory={true}
                        name="email"
                        value={formState.values.email || ""}
                        error={hasError("email")}
                        helperText={hasError("email") ? formState.errors.email[0] : null}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{marginTop: "34px", marginBottom: "23px"}}
                >
                    <CustomButton
                        title={"Check My Exposure"}
                        onClick={submitVerification}
                        customWidth={"256px"}
                    />
                </Grid>
                <Grid item xs={12} id={"google-re-captcha"}></Grid>
            </Grid>
        </div>
    </div>;
}

export default withGoogleReCaptcha(LandingPage);