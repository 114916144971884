import React from "react";
import {Grid, Typography, IconButton} from "@material-ui/core";
import {
    aboutUsFooterItems,
    footerIcons,
    learnFooterItems,
    partnersFooterItems,
    productFooterItems,
    ProtectTitle,
    Trademark,
    whoWeServeFooterItems,
} from "../utils/constants";
import {FooterBar} from "./FooterBar";
import {LogoIcon} from "../Components/Icons/Logo";
import {FooterText} from "../Components/FooterText";
import styles from './styles';

export const Footer = ({firstPage, loading}) => {
    const classes = styles();

    return (
        <>
            {firstPage || loading ? (
                <div></div>
            ) : (
                <FooterBar/>
            )}
            <div className={classes.footerContainer}>
                <Grid
                    container
                    justifyContent={"space-between"}
                >
                    <Grid item>
                        <LogoIcon/>
                        <Typography className={classes.protectText}>{ProtectTitle}
                            <sup><span className={classes.tradeMark}>{Trademark}</span></sup>
                        </Typography>
                        <div className={classes.socialMediaContainer}>
                            {footerIcons.map((footerIcon) => (
                                <IconButton
                                    key={footerIcon.link}
                                    onClick={() => {
                                        window.open(footerIcon.link);
                                    }}
                                >
                                    {footerIcon.icon}
                                </IconButton>))}
                        </div>
                        <div className={classes.logosContainer}>
                            <img
                                src={"/FooterLogo1.png"}
                                alt={"FooterLogo1"}
                                style={{marginRight: "20px"}}
                            />
                            <img
                                src={"/FooterLogo2.png"}
                                alt={"FooterLogo2"}
                                style={{marginRight: "20px"}}
                            />
                            <img src={"/FooterLogo3.png"} alt={"FooterLogo3"}/>
                        </div>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <div style={{minWidth: '315px'}}>
                                <Grid item md={8} sm={12}>
                                    <FooterText
                                        id={"Product"}
                                        text={"Product"}
                                        itemList={productFooterItems}
                                    />
                                    <FooterText
                                        id={"WhoWeServe"}
                                        text={"Who We Serve"}
                                        itemList={whoWeServeFooterItems}
                                    />
                                    <FooterText
                                        id={"Partners"}
                                        text={"Partners"}
                                        itemList={partnersFooterItems}
                                    />
                                </Grid>
                            </div>
                            <div style={{minWidth: '170px'}}>
                                <Grid item >
                                    <FooterText
                                        id={"Learn"}
                                        text={"Learn"}
                                        itemList={learnFooterItems}
                                    />
                                    <FooterText
                                        id={"AboutUs"}
                                        text={"About Us"}
                                        itemList={aboutUsFooterItems}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <hr className={classes.divider}/>
                <div className={classes.copyrightContainer}>
                    <Typography className={classes.copyrightText}>
                        © 2022. BlackCloak, INC. ALL RIGHTS RESERVED.
                    </Typography>
                    <Typography className={classes.copyrightText} onClick={() => {
                        window.open("https://blackcloak.io/sitemap/");
                    }}>
                        Sitemap
                    </Typography>
                </div>
            </div>
        </>
    );
};

