import YouTubeIcon from "@mui/icons-material/YouTube";
import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

export const headerButton1 = "Request a demo";
export const headerButton2_1 = "Menu";
export const headerButton2_2 = "Close";

export const Trademark = "TM";
export const ProtectTitle = "Protect Your Digital Life";
export const firstTitle = "How Big Is Your Digital FootPrint?";
export const description =
  "You may be surprised to learn just how much of you private data is exposed by online data brokers who routinely collect and sell your personal information. Your email may also be part of a website breach or other data leak that may be exposed your sensitive account login credential and passwords.";
export const firstPageFormTitle = "Data Broker Exposure Search";
export const firstPageFormTitle2 = "Data Breach Search";

export const loadingMsg1_1 = "Scanning";
export const loadingMsg1_2 = "More than 120";
export const loadingMsg1_3 = "Data Brokers For Your Data...";
export const loadingMsg2 = "Scanning The Dark Web Now...";

export const successfullMsg1 = "No results found.";
export const successfullMsg1_1 = "Good job keeping a low profile!";
export const successfullMsg2 =
  "Your information was not detected \non any data broker sites.";
export const successfullMsg3 =
  "Your email is not associated \nwith any known breaches.";

export const failMsgDataBrokerMsg = "YOUR DATA BROKER EXPOSURE";
export const failMsgProfilesOn = "profiles on";
export const failMsgDataBrokerSites = "data broker sites";
export const failMsgDataBreachMsg = "YOUR DATA BREACH EXPOSURE";
export const failMsgDataBreach = "data breaches";
export const failMsgDataBreachSite =
  "A site breach is a type of cyber attack where sensitive data is stolen or taken from a company or website through unauthorized access.";
export const failMsgDataBreachItem = "Compromised data: ";

export const footerBarMsg1 = "Want to reduce";
export const footerBarMsg1_1 = "Your exposure?";
export const footerBarMsg2 =
  "Learn how reducing your digital footprint can improve your overall security and protect against identity theft, fraud, scams, stalking, and more.";
export const footerBarButtonTest = "Contact BlackCloak";

export const footerIcons = [
  {
    icon: <YouTubeIcon style={{ fill: "#6f717e" }} fontSize={"large"} />,
    link: "https://www.youtube.com/channel/UCHyCzUdnikexI4eyCW28bAQ",
  },
  {
    icon: <TwitterIcon style={{ fill: "#6f717e" }} fontSize={"large"} />,
    link: "https://twitter.com/blackcloakcyber",
  },
  {
    icon: <LinkedInIcon style={{ fill: "#6f717e" }} fontSize={"large"} />,
    link: "https://www.linkedin.com/company/blackcloak/",
  },
  {
    icon: <FacebookIcon style={{ fill: "#6f717e" }} fontSize={"large"} />,
    link: "https://www.facebook.com/BlackCloakCP",
  },
  {
    icon: <InstagramIcon style={{ fill: "#6f717e" }} fontSize={"large"} />,
    link: "https://www.instagram.com/blackcloakcyber/",
  },
];

export const productFooterItems = [
  {
    name: "Product",
    link: "https://blackcloak.io/product/",
  },
];

export const whoWeServeFooterItems = [
  {
    name: "Executives & Companies",
    link: "https://blackcloak.io/companies/",
  },
  {
    name: "Individuals & Families",
    link: "https://blackcloak.io/individuals/",
  },
];

export const partnersFooterItems = [
  {
    name: "Become a Partner",
    link: "https://blackcloak.io/partners/",
  },
];

export const learnFooterItems = [
  {
    name: "Resources",
    link: "https://blackcloak.io/resources/",
  },
  {
    name: "Blog",
    link: "https://blackcloak.io/cybersecurity-blog/",
  },
  {
    name: "Events",
    link: "https://blackcloak.io/cybersecurity-events-conferences/",
  },
];

export const aboutUsFooterItems = [
  {
    name: "Leadership",
    link: "https://blackcloak.io/leadership/",
  },
  {
    name: "News",
    link: "https://blackcloak.io/news-media/",
  },
  {
    name: "Awards & Recognition",
    link: "https://blackcloak.io/awards-recognition//",
  },
  {
    name: "Careers",
    link: "https://blackcloak.io/careers/",
  },
  {
    name: "Contact",
    link: "https://blackcloak.io/contact/",
  },
  {
    name: "Privacy Policy",
    link: "https://blackcloak.io/privacy-policy/",
  },
  {
    name: "Terms & Conditions",
    link: "https://blackcloak.io/terms-conditions/",
  },
];

export const footerItems = [
  {
    title: "Product",
    items: productFooterItems,
  },
  {
    title: "Who We Serve",
    items: whoWeServeFooterItems,
  },
  {
    title: "Partners",
    items: partnersFooterItems,
  },
  {
    title: "Learn",
    items: learnFooterItems,
  },
  {
    title: "About Us",
    items: aboutUsFooterItems,
  },
];
