export const validationSchema = {
    email: {
        presence: {
            allowEmpty: false,
            message: "Please enter a valid email address",
        },
        email: {
            message: "Email address is not valid",
        },
        length: {
            maximum: 64,
        },
    },
    firstName: {
        presence: {
            allowEmpty: false,
            message: "Please enter first name",
        },
        format: {
            pattern: "[a-z]+",
            flags: "i",
            message: "First name should not contain special characters (\",.>' '1)",
        },
        length: {
            maximum: 16,
            tooLong: "First name is too long (maximum 16 characters)",
            minimum: 3,
            tooShort: "First name is too short (minimum 3 characters)",
        },
    },
    lastName: {
        presence: {
            allowEmpty: false,
            message: "Please enter last name",
        },
        format: {
            pattern: "[a-z]+",
            flags: "i",
            message: "Last name should not contain special characters (\",.>' '1)",
        },
        length: {
            maximum: 16,
            tooLong: "First name is too long (maximum 16 characters))",
            minimum: 3,
            tooShort: "First name is too short (minimum 3 characters)",
        },
    },
    zipCode: {
        presence: {
            allowEmpty: false,
            message: "Please enter zip code",
        },
    },
};
