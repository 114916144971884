import React from "react";
import { Grid } from "@mui/material";
import {Typography} from "@material-ui/core";
import {
  footerBarButtonTest,
  footerBarMsg1,
  footerBarMsg1_1,
  footerBarMsg2,
} from "../utils/constants";
import { CustomButton } from "../Components/CustomButton";
import styles from './styles';
import clsx from "clsx";

export const FooterBar = () => {
  const classes = styles();

  return (
    <Grid
      container
      className={classes.footerBarContainer}
      height={"374px"}>
      <Grid item md={6} xs={12} >
          <div className={classes.reduceExposureContainer}>
            <Typography className={classes.reduceExposureText}>{footerBarMsg1}</Typography>
            <Typography className={clsx(classes.reduceExposureText, classes.fwBold)}>
          {footerBarMsg1_1}
        </Typography>
          </div>
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography className={classes.learnText}>
          {footerBarMsg2}
        </Typography>
          <CustomButton
            title={footerBarButtonTest}
            onClick={() => {
              window.open("https://blackcloak.io/contact/");
            }}
            customWidth={266}
          />
      </Grid>
    </Grid>
  );
};
