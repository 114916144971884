import {makeStyles} from "@material-ui/core";

const styles = makeStyles({
    footerBarContainer:{
        width: "100%",
        display:"flex",
        height:"374px",
        alignContent: "center",
        backgroundColor: "white",
        backgroundImage: "url('/HandPhoneFadePhoto.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize:"cover",
        padding: "0 10px 0 10px",
        backgroundPosition: "left top",
    },
    reduceExposureContainer:{
        display:"flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems:"center",

    },
    reduceExposureText:{
        fontFamily:"Montserrat",
        fontSize: "36px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.39,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#0b0e1a",
    },
    fwBold:{
      fontWeight: "bold"
    },
    learnText:{
        fontFamily:"Montserrat",
        fontSize: "21px",
        fontWeight: "700",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.67,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#0b0e1a",
        marginBottom: "45px"
    },
    footerContainer: {
        maxWidth:'1310px',
        margin: "160px 20% 154px 20%",
        "@media only screen and (max-width: 1556px)":{
            margin: "160px 58px 154px 58px"
        },
        "@media only screen and (max-width: 480px)":{
            margin: "160px 10px 154px 10px"
        }
    },
    divider:{
        border: "solid 1px #707070",
        opacity: 0.48,
        marginBottom: "47px"
    },
    protectText: {
        marginTop: "12px",
        fontFamily:"Montserrat",
        fontSize: "21px",
        fontWeight: "bold",
        letterSpacing: "1.5px",
        textAlign: "left",
        color: "#fff",
    },
    tradeMark: {
        fontFamily:"Montserrat",
        fontWeight: "bold",
        fontSize: "13px",
        color: "#fff",
    },
    productContainer:{
      textAlign: "right"
    },
    socialMediaContainer: {
        marginTop: "20px",
    },
    logosContainer: {
        margin: "10px 0 27px 20px"
    },
    copyrightContainer:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        "@media only screen and (max-width: 488px)":{
            flexDirection: "column"
        }
    },
    copyrightText:{
        fontFamily:"Montserrat",
        fontSize: "14px",
        fontWeight: 700,
        letterSpacing: 1.71,
        textAlign: "left",
        color: "#fff",
        opacity: 0.28,
    },
});

export default styles;