import React from "react";
import { SvgIcon } from "@mui/material";

export const LogoIcon = () => {
  return (
    <SvgIcon
      sx={{
        width: "258px",
        height: "20px",
      }}
      inheritViewBox
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="257.689"
        height="17.815"
        viewBox="215 5563 257.689 17.815"
      >
        <g data-name="Logo">
          <path
            d="M215 5563.156h8.364c3.982 0 5.974 1.498 5.974 4.495a3.712 3.712 0 0 1-.928 2.592 4.32 4.32 0 0 1-2.342 1.332 5.87 5.87 0 0 1 2.831 1.308 3.599 3.599 0 0 1 1.26 2.925c0 1.776-.545 3.028-1.635 3.757-1.09.73-2.705 1.094-4.844 1.094H215v-17.503Zm8.521 7.753a3.526 3.526 0 0 0 2.484-.865 3 3 0 0 0 .943-2.319c0-1.902-1.194-2.853-3.584-2.853h-5.848v6.04l6.005-.003Zm.095 8.063a5.273 5.273 0 0 0 3.081-.76c.713-.508 1.07-1.413 1.07-2.714a2.625 2.625 0 0 0-1.18-2.26 5.027 5.027 0 0 0-2.971-.83h-6.1v6.565l6.1-.001Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 1"
          />
          <path
            d="M241.476 5579.863a3 3 0 0 1-1.227-2.723v-13.984h2.517v13.722c-.07.629.207 1.246.722 1.605a3.726 3.726 0 0 0 1.95.44h8.301v1.733h-8.836a6.257 6.257 0 0 1-3.427-.793Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 2"
          />
          <path
            d="M269.838 5563.156h3.205l7.926 17.503h-2.578l-2.327-4.994h-9.306l-2.263 4.994h-2.58l7.923-17.503Zm5.597 10.797-3.993-9.132-4.056 9.132h8.05Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 3"
          />
          <path
            d="M293.298 5580.101a4.742 4.742 0 0 1-2.58-2.627 14.471 14.471 0 0 1-.849-5.614c-.085-1.846.215-3.69.88-5.41a5.078 5.078 0 0 1 2.674-2.676 12.429 12.429 0 0 1 4.78-.761c.963 0 2.069.059 3.317.178 1.248.12 2.354.274 3.317.464v1.407a74.5 74.5 0 0 0-2.832-.238 49.84 49.84 0 0 0-3.428-.12 9.35 9.35 0 0 0-3.679.583 3.672 3.672 0 0 0-1.934 2.116 12.65 12.65 0 0 0-.613 4.484c-.067 1.548.13 3.097.582 4.578a3.489 3.489 0 0 0 1.87 2.093c1.19.427 2.45.617 3.71.559a50.39 50.39 0 0 0 6.321-.31v1.428c-.964.175-2.049.313-3.254.416a41.98 41.98 0 0 1-3.57.155c-1.6.06-3.198-.178-4.712-.705Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 4"
          />
          <path
            d="M314.992 5563.156h2.515v7.92h4.056l6.352-7.92h2.641l-7.011 8.751 7.106 8.752h-2.673l-6.415-7.872h-4.056v7.872h-2.515v-17.503Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 5"
          />
          <path
            d="M342.695 5580.101a4.744 4.744 0 0 1-2.58-2.627 14.467 14.467 0 0 1-.848-5.613c-.085-1.846.215-3.69.88-5.41a5.077 5.077 0 0 1 2.673-2.676 12.442 12.442 0 0 1 4.78-.762c.964 0 2.07.06 3.317.18 1.247.118 2.353.273 3.317.463v1.406c-.734-.08-1.678-.16-2.832-.238a49.846 49.846 0 0 0-3.427-.12 9.35 9.35 0 0 0-3.68.583 3.675 3.675 0 0 0-1.933 2.116 12.65 12.65 0 0 0-.614 4.484c-.068 1.548.13 3.097.581 4.578a3.487 3.487 0 0 0 1.872 2.093c1.189.427 2.45.617 3.71.559 2.111.03 4.222-.074 6.32-.31v1.428a35.33 35.33 0 0 1-3.254.416 41.993 41.993 0 0 1-3.57.155c-1.6.06-3.198-.178-4.712-.705Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 6"
          />
          <path
            d="M365.618 5579.863a2.999 2.999 0 0 1-1.227-2.723v-13.984h2.516v13.722c-.07.629.208 1.246.723 1.605a3.725 3.725 0 0 0 1.95.44h8.3v1.733h-8.836a6.258 6.258 0 0 1-3.426-.793Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 7"
          />
          <path
            d="M390.474 5580.149a4.924 4.924 0 0 1-2.831-2.545c-.64-1.26-.96-3.135-.959-5.625-.103-1.935.236-3.87.99-5.651a5.162 5.162 0 0 1 2.878-2.629 15.125 15.125 0 0 1 5.062-.69 15.744 15.744 0 0 1 5.157.679 4.936 4.936 0 0 1 2.832 2.615 13.374 13.374 0 0 1 .942 5.672c0 2.49-.32 4.364-.958 5.625a4.926 4.926 0 0 1-2.832 2.545c-3.374.872-6.91.872-10.284 0l.003.004Zm8.993-1.558a3.491 3.491 0 0 0 1.966-2.045c.488-1.47.702-3.018.629-4.567a13.241 13.241 0 0 0-.63-4.709 3.448 3.448 0 0 0-1.965-2.057 11.257 11.257 0 0 0-3.851-.511 10.916 10.916 0 0 0-3.742.511 3.596 3.596 0 0 0-2.028 2.081 12.412 12.412 0 0 0-.676 4.685c-.072 1.55.13 3.1.596 4.578a3.383 3.383 0 0 0 1.937 2.035c1.265.403 2.59.58 3.915.523a11.022 11.022 0 0 0 3.85-.524Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 8"
          />
          <path
            d="M421.368 5563.156h3.204l7.924 17.503h-2.579l-2.323-4.993h-9.308l-2.264 4.994h-2.578l7.924-17.504Zm5.597 10.797-3.991-9.132-4.056 9.132h8.047Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 9"
          />
          <path
            d="M442.28 5563.156h2.515v7.92h4.056l6.352-7.92h2.641l-7.011 8.751 7.106 8.752h-2.673l-6.417-7.871h-4.056v7.871h-2.515l.001-17.503Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 10"
          />
          <g data-name="Group 77">
            <path
              d="M466.34 5563.814h-1.604v4.362h-.722v-4.362h-1.638v-.658h3.965v.658Zm6.35 4.362h-.724v-4.216h-.024l-1.675 4.216h-.469l-1.675-4.216h-.024v4.216h-.723v-5.02h1.12l1.555 3.85 1.554-3.85h1.084v5.02Z"
              fill="#fff"
              fillRule="evenodd"
              data-name="Path 107"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
