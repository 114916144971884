import React from "react";
import { SvgIcon } from "@mui/material";

export const FootprintIcon = () => {
    return (
        <SvgIcon
            sx={{
                width: "16.879",
                height: "21.099",
                marginBottom: "-10px"
            }}
            inheritViewBox
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16.879" height="21.099" viewBox="0 0 16.879 21.099">
                <path d="M11.126 8.967A6.4 6.4 0 0 0 7.675 10.1a4.816 4.816 0 0 1-3.455 1.128H2.11A2.186 2.186 0 0 0 0 13.487a2.257 2.257 0 0 0 2.11 2.336h2.11l1.9.491a11.909 11.909 0 0 0 3.551.565c3.649 0 7.213-1.816 7.213-4.52-.005-2.265-2.778-3.392-5.758-3.392zm-7.434 5.8H2.11a1.217 1.217 0 0 1-1.055-1.282 1.138 1.138 0 0 1 1.055-1.2h1.582zm2.687.524-1.632-.422v-2.605a5.633 5.633 0 0 0 3.4-1.208c3.01-2.142 7.678-.612 7.678 1.3-.001 2.05-4.006 4.549-9.446 2.937zM13.886 0a11.774 11.774 0 0 0-3.551.565l-1.9.49H6.33a2.239 2.239 0 0 0-2.11 2.336 2.168 2.168 0 0 0 2.11 2.258h2.11a4.816 4.816 0 0 1 3.455 1.133 6.393 6.393 0 0 0 3.452 1.129c2.98 0 5.753-1.127 5.753-3.392C21.1 1.842 17.575 0 13.886 0zM7.912 4.6H6.33a1.138 1.138 0 0 1-1.055-1.2A1.217 1.217 0 0 1 6.33 2.11h1.582zm7.434 2.232a5.348 5.348 0 0 1-2.846-.94 5.936 5.936 0 0 0-3.533-1.277V2.007l1.669-.429a10.713 10.713 0 0 1 3.25-.524c1.721 0 6.158.857 6.158 3.465 0 1.534-2.364 2.308-4.698 2.308z" transform="rotate(-90 10.55 10.55)" style={{fill:"#76b7c7"}} />
            </svg>
        </SvgIcon>
    );
};
