import React from "react";
import {
  failMsgDataBreach,
  failMsgDataBreachMsg,
} from "../../../utils/constants";
import { FailDataBreachItem } from "./FailDataBreachItem";
import {Typography, Grid} from "@material-ui/core";
import styles from './styles';
import clsx from "clsx";

export const FailDataBreach = ({ dataBreaches, dataBreachesSites }) => {
    const classes = styles();
    const lastNo = dataBreachesSites.length - 1;
    return (
    <>
        <Typography className={clsx(classes.title, classes.secondTitleMargin)}>
          {failMsgDataBreachMsg}
        </Typography>
        <div className={classes.breachesContainer}>
            <Typography className={classes.subtitle}>
                <span className={classes.boldText}>{dataBreaches}</span>
                &nbsp;{failMsgDataBreach}&nbsp;
            </Typography>
        </div>
          {/*<Typography className={classes.viewBreachesText}>*/}
          {/*  View site breaches*/}
          {/*</Typography>*/}
          {/*  <Typography className={classes.breachExplanationText}>*/}
          {/*      {failMsgDataBreachSite}*/}
          {/*  </Typography>*/}
      <Grid container>
        {dataBreachesSites.map((dataBreachedSite, idx) => {
          return (
            <FailDataBreachItem
              key={dataBreachedSite.siteName + idx}
              siteName={dataBreachedSite.siteName}
              compromisedItems={dataBreachedSite.compromisedItems}
              index={idx}
              lastNo={lastNo}
            />
          );
        })}
      </Grid>
    </>
  );
};
