import {makeStyles} from "@material-ui/core";

const styles = makeStyles({
    container:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems:"center",
        maxWidth: "717px"
    },
    img:{
      height: "150px",
      width: "150px",
      marginBottom:"57px",
    },
    title:{
        fontFamily: "Montserrat",
        fontSize: "40px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.65,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#fff"
   },
    fwBold:{
        fontWeight: "bold"
    },
});

export default styles;