import React, { useState } from "react";
import { ContentContainer } from "./Content/ContentContainer";
import { Footer } from "./Footer/Footer";
import { SnackbarProvider } from 'notistack';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
        error:{
            backgroundColor: "#948771 !important"
        }
    });

function App() {
    const classes = useStyles();
    const [firstPage, setFirstPage] = useState(true);
    const [loading, setLoading] = useState(null);

  return (
    <SnackbarProvider
        autoHideDuration={4000}
        maxSnack={1}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        classes={{
            variantError:classes.error
        }}
        >
        <ContentContainer
          firstPage={firstPage}
          setFirstPage={setFirstPage}
          loading={loading}
          setLoading={setLoading}
        ></ContentContainer>
        <Footer firstPage={firstPage} loading={loading}></Footer>
    </SnackbarProvider>
  );
}

export default App;
