import React, { useEffect, useState } from "react";
import {Typography} from "@material-ui/core";
import {
  successfullMsg1,
  successfullMsg1_1,
  successfullMsg2,
  successfullMsg3,
} from "../../../utils/constants";
import styles from "./styles";
import clsx from "clsx";


export const SuccessResponse = () => {
  const classes = styles();

  const [firstLoadMessage, setFirstLoadMessage] = useState(true);
  const [secondLoadMessage, setSecondLoadMessage] = useState(true);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    sleep(2000).then(() => setFirstLoadMessage(false));
  }, [firstLoadMessage]);

  useEffect(() => {
    if (!firstLoadMessage) {
      sleep(2000).then(() => setSecondLoadMessage(false));
    }
  }, [firstLoadMessage, secondLoadMessage]);

  return (
    <div className={classes.container}>
        <img
          className={classes.img}
          src={"/SuccessLogo.png"}
          alt={"SuccessLogo"}
        />
      {firstLoadMessage ? (
        <>
            <Typography className={classes.title}>
              {successfullMsg1}
            </Typography>
            <Typography className={clsx(classes.title, classes.fwBold)}>
              {successfullMsg1_1}
            </Typography>
        </>
      ) : (
        <>
          {secondLoadMessage ? (
              <Typography className={classes.title}>
                {successfullMsg2}
              </Typography>
          ) : (
              <Typography className={classes.title}>
                {successfullMsg3}
              </Typography>
          )}
        </>
      )}
    </div>
  );
};
