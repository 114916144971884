import React from "react";
import {makeStyles, TextField} from "@material-ui/core";


const useStyles = makeStyles({
    textField: {
        '& input':{
            color: "white",
            backgroundColor: "rgba(84, 90, 114, 0.1)"
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#948771',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#948771',
            },
            '&:hover fieldset': {
                borderColor: '#948771',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#948771',
            },
        },
    },
    textTitle: {
        fontFamily:"Open Sans",
        fontSize:'16px'
    }
});

export const InputForm = ({ title,name ,value, error, helperText, handleChange, mandatory }) => {
    const classes = useStyles();

      return (
        <>
          <div
            style={{
              display: "flex",
              marginBottom: "9px",
              color: "white",
              opacity: 1,
            }}
          >
            <div className={classes.textTitle}>{title}</div>
            {mandatory ? <div style={{ color: "red" }} className={classes.textTitle}>*</div> : null}
          </div>
          <TextField
            value={value}
            name={name}
            error={error}
            helperText={helperText}
            onChange={handleChange}
            variant="outlined"
            className={classes.textField}
            fullWidth
            focused={false}
          ></TextField>
        </>
      );
};
