import {makeStyles} from "@material-ui/core";

const styles = makeStyles({
    container:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems:"center",
        maxWidth:"770px",
    },
    title:{
        marginTop: "50px",
        marginBottom: "89px",
        fontFamily: "Montserrat",
        fontSize: "40px",
        fontWeight: "bold",
        lineHeight: 2.03,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#fff"
   },
    secondTitleMargin:{
      marginTop: "125px"
    },
    subtitle:{
        fontFamily: "Montserrat",
        fontSize: "36px",
        fontWeight: "normal",
        lineHeight: 1.39,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#fff"
    },
    boldText:{
        fontWeight: "bold",
        color: "#f44848",
    },
    breachesContainer: {
        border: "1px solid #948771",
        padding: "5px 40px 5px 40px",
        height: "137px",
        width: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    viewBreachesText:{
        marginTop:"77px",
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: "normal",
        fontStretch:"normal",
        fontStyle:"normal",
        textDecoration:"underline",
        cursor:"pointer",
        lineHeight: 1.2,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#fff"
    },
    breachExplanationText:{
        marginTop:"31px",
        marginBottom: "73px",
        maxWidth:"513px",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch:"normal",
        fontStyle:"normal",
        lineHeight: 1.75,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#fff"
    },
    siteName:{
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch:"normal",
        fontStyle:"normal",
        lineHeight: 1.2,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#928773",
        margin: "39px 0px 9px 0px"
    },
    compromisedDataTitle:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch:"normal",
        fontStyle:"normal",
        lineHeight: 1.75,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#fff",
        marginBottom: "5px"
    },
    fwNormal:{
        fontWeight:"normal"
    },
    compromisedBox:{
        marginTop:"10px",
        border: "1px solid #938670",
        backgroundColor: "#4F4B46",
        textAlign: "center",
        marginRight: "12px",
        padding: "5px"
    },
    compromisedBoxText:{
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch:"normal",
        fontStyle:"normal",
        lineHeight: 2,
        letterSpacing: 1.2,
        textAlign: "center",
        textTransform:"uppercase",
        color: "#fff",
    },
    border:{
        opacity: "0.4",
        border: "solid 1px #707070",
        marginTop: "38px",
    }
});

export default styles;