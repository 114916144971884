import React from "react";
import { SuccessResponse } from "./SuccessResponse/SuccessResponse";
import { Grid } from "@mui/material";
import { CustomButton } from "../../Components/CustomButton";
import { FailResponseContainer } from "./FailResponse/FailResponseContainer";

export const ResponseContainer = ({
  setFirstPage, response,

}) => {
  return (
    <div>
      <Grid
        container
        sx={{ display: "grid", marginTop: "115px" }}
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        justifyItems={"center"}
      >
        {!response.hasVulnerability ? (
          <SuccessResponse />
        ) : (
          <FailResponseContainer
            hasDataBrokerExposure={response.hasDataBrokerExposure}
            profiles={response.brokerExposureProfiles}
            sites={response.brokerExposureSites}
            hasDataBreachExposure={response.hasDataBreachExposure}
            dataBreaches={response.dataBreaches}
            dataBreachesSites={response.dataBreachesSites}
          />
        )}
        <Grid item xs={12} marginTop={"126px"}>
          <CustomButton
            title={"New search"}
            onClick={() => {
              setFirstPage(true);
              window.location.reload(false);
              window.scrollTo(0, 0)
            }}
            customWidth={266}
          />
        </Grid>
      </Grid>
    </div>
  );
};
