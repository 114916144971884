import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const root = ReactDOM.createRoot(document.getElementById("root"));
// TODO Remove this hardcoded value as we cannot use the key for lower environments
root.render(
    <GoogleReCaptchaProvider
      reCaptchaKey="6LeQZgokAAAAAIR5rK7CIZ3_eyRjJi0kSOyx4mSN"
      scriptProps={{ appendTo: "head" }}
    >
        <App />
    </GoogleReCaptchaProvider>
);
