import React, { useState } from "react";
import {makeStyles} from "@material-ui/core";
import { Loading } from "./Loading";
import { ResponseContainer } from "./Response/ResponseContainer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import LandingPage from "../LandingPage";
import {Header} from "../Header/Header";

const useStyles = makeStyles({
  container:{
    margin: "59px 58px 0px 58px",
    "@media only screen and (max-width: 480px)":{
      margin: "59px 10px 0px 10px"
    }
  }
});

export const ContentContainer = ({
  firstPage,
  setFirstPage,
  loading,
  setLoading,
}) => {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [response, setResponse] = useState(null);
  return (
    <div className={classes.container}>
      <Header />
      {loading ? (
        <Loading></Loading>
      ) : (
        <>
          {firstPage ? (<LandingPage
              setResponse={setResponse}
              executeRecaptcha={executeRecaptcha}
              setLoading={setLoading}
              setFirstPage={setFirstPage}
              />) : (
            <ResponseContainer
              setFirstPage={setFirstPage}
              response={response}
            />
          )}
        </>
      )}
    </div>
  );
};
