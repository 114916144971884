import {makeStyles} from "@material-ui/core";

const styles = makeStyles({
    navigationContainer:{
        display:"flex",
        flexDirection:"row",
        padding:"0px 58px 0px 58px",
        height: "80px",
        width:"100%",
        justifyContent: "space-between",
        alignItems: "center",
        textTransform:"uppercase",
        backgroundColor: "#0b0e1a",
        boxShadow: "unset",
        // zIndex: 999,
        "@media only screen and (max-width: 480px)":{
            padding: "0px 10px 0px 10px"
        }
    },
    requestDemoContainer:{
        display:"flex",
        flexDirection:"row",
        alignItems:"baseline",
    },
    requestDemoText: {
        fontFamily:"Montserrat",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: 1.14,
        letterSpacing: "1.5px",
        textAlign: "right",
        color: "#fff",
        cursor:"pointer",
        "&:hover":{
            color: "#8e806b",
        }
    },
    slash:{
      color:  "#4a4b52"
    },
    menuText: {
        fontFamily:"Montserrat",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: 1.14,
        letterSpacing: "1.5px",
        textAlign: "right",
        cursor:"pointer",
        color: "#8e806b",
        "&:hover":{
            opacity: 0.8,
        }
    },
    closeBtnContainer:{
        display:"flex",
        justifyContent: "flex-end",
        padding:"25px 58px 0px 58px",
        "@media only screen and (max-width: 480px)":{
            padding: "25px 20px 0px 20px"
        }
    },
    iconButton:{
        padding: "0px !important"
    },
    closeIcon:{
        color: "#fff",
    },
    icon:{
        color: "#8e806b",
        width: "30px",
        height: "30px",
    },
    drawerContent:{
        padding: "70px 95px 0px 95px",
        zIndex: 1,
        "@media only screen and (max-width: 480px)":{
            padding: "70px 20px 0px 20px"
        }
    },
    drawer:{

    }
});

export default styles;