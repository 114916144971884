import React, { useState } from "react";
import { Grid, IconButton, Drawer} from "@mui/material";
import {Typography,AppBar, Hidden, useMediaQuery} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';
import { LogoIcon } from "../Components/Icons/Logo";
import clsx from "clsx";

import {
  footerIcons,
  footerItems,
  headerButton1,
  headerButton2_1,
} from "../utils/constants";
import { FooterText } from "../Components/FooterText";
import styles from './styles';

export const Header = () => {
    const classes = styles();
    const [open, setOpen] = useState(false);

    const matches = useMediaQuery('(max-width:768px)');

    const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const content = () => {
    return (
        <div className={classes.drawerContent}>
          <Grid container display={"block"} >
            {footerItems.map((footerItem, index) => (
              <Grid item key={footerItem.title + index}>
                <FooterText text={footerItem.title} itemList={footerItem.items} />
              </Grid>
            ))}
            {footerIcons.map((footerIcon, index) => {
              return (
                <IconButton
                  key={footerIcon.link + index}
                  onClick={() => {
                    window.open(footerIcon.link);
                  }}
                >
                  {footerIcon.icon}
                </IconButton>
              );
            })}
          </Grid>
        </div>
    );
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
              zIndex: 2,
              width: matches? "100%" : "50%",
              backgroundColor: "#181E36",
          },
        }}
      >
          <div className={classes.closeBtnContainer}>
          <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon className={classes.closeIcon}/>
          </IconButton>
          </div>
        {content()}
      </Drawer>
        <AppBar className={classes.navigationContainer}>
            <LogoIcon />
            <div className={classes.requestDemoContainer}>
                <Hidden lgUp>
                    <IconButton color="inherit" className={classes.iconButton} onClick={toggleDrawer(true)}>
                        <MenuIcon className={classes.icon}/>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                <Typography className={classes.requestDemoText} onClick={() => {
                    window.open("https://blackcloak.io/request-a-demo/");
                }}>
                    {headerButton1}
                </Typography>
                <Typography className={clsx(classes.requestDemoText, classes.slash)}>&emsp;/&emsp;</Typography>
                <Typography className={clsx(classes.requestDemoText, classes.menuText)} onClick={toggleDrawer(true)}>
                    {headerButton2_1}
                </Typography>
                </Hidden>
            </div>
        </AppBar>
    </>
  );
};
