import React from "react";
import {
  failMsgDataBrokerMsg,
  failMsgDataBrokerSites,
  failMsgProfilesOn,
} from "../../../utils/constants";
import {Typography} from "@material-ui/core";
import styles from './styles';

export const FailDataBroker = ({ profiles, sites }) => {
    const classes = styles();

    return (
    <div>
      <Typography className={classes.title}>
        {failMsgDataBrokerMsg}
      </Typography>
      <div className={classes.breachesContainer}>
        <Typography className={classes.subtitle}>
          <span className={classes.boldText}>{profiles}</span>
          &nbsp;{failMsgProfilesOn}&nbsp;
          <span className={classes.boldText}>{sites}</span>
          &nbsp;{failMsgDataBrokerSites}
        </Typography>
      </div>
    </div>
  );
};
