import React, { useEffect, useState } from "react";
import {makeStyles, Typography} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import clsx from "clsx";

import {
  loadingMsg1_1,
  loadingMsg1_2,
  loadingMsg1_3,
  loadingMsg2,
} from "../utils/constants";


const useStyles = makeStyles({
  container:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    marginTop: "300px"
  },
  loading:{
    display:"flex",
    justifyContent: "center",
    alignItems:"center",
    width: "88px",
    height: "88px",
    position: "relative",
    marginBottom: "26px"
  },
  spinnerSector:{
    position:"absolute",
    width:"100%",
    height: "100%",
    borderRadius: "50%",
    border: "5px solid #913030",
    borderTop: "5px solid #f44848",
    borderRight: "5px solid #f44848",
    animation: "loading 0.8s infinite linear",
  },
  secondaryBorder:{
    position:"absolute",
    width:"90%",
    height: "90%",
    borderRadius: "50%",
    border: "10px solid #913030",
    // backgroundImage: "radial-gradient(circle at 50% 50%, #6b79ce, rgba(36, 37, 50, 0) 71%)",
    opacity: 0.31,
  },
  icon:{
    position: "absolute",
    color: "#f44848",
    width: "50px",
    height: "50px",
    zIndex: 5
  },
  title:{
    fontFamily: "Montserrat",
    fontSize: "40px",
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    marginBottom: "26px",
    textAlign: "center",
    color: "#fff"
  },
  highlightedText: {
    fontWeight:"bold",
    color: "#948771",
    fontSize:"50px"
  }
});

export const Loading = () => {
  const classes = useStyles();
  const [firstLoadMessage, setFirstLoadMessage] = useState(true);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    sleep(3000).then(() => setFirstLoadMessage(false));
  }, [firstLoadMessage]);

  return (
    <div className={classes.container}>
        <div className={classes.loading}>
          <SearchIcon className={classes.icon}/>
          <div className={classes.secondaryBorder}></div>
          <div className={classes.spinnerSector}></div>
        </div>

      {firstLoadMessage ? (
        <>
            <Typography className={classes.title}>{loadingMsg1_1}</Typography>
            <Typography className={clsx(classes.title, classes.highlightedText)}>
              {loadingMsg1_2}
            </Typography>
            <Typography className={classes.title}>{loadingMsg1_3}</Typography>
        </>
      ) : (
          <Typography className={classes.title}>{loadingMsg2}</Typography>
      )}
    </div>
  );
};
