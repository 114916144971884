import React from "react";
import {Typography, Grid} from "@material-ui/core";
import { failMsgDataBreachItem } from "../../../utils/constants";
import styles from "./styles";

export const FailDataBreachItem = ({
  siteName,
  compromisedItems,
  index,
  lastNo,
}) => {
    const classes = styles();

    return (
        <Grid item xs={12}>
        <Typography className={classes.siteName}>
        {siteName}
        </Typography>
        <Grid item xs={9}>
            <Typography className={classes.compromisedDataTitle}>
                {failMsgDataBreachItem}
                </Typography>
        </Grid>
            <Grid item xs={12}>
                <Grid container>
            {compromisedItems.map((itemName, itemNameIdx) => (
              <div
                key={itemName + itemNameIdx}
                className={classes.compromisedBox}>
                <Typography className={classes.compromisedBoxText}>
                  {itemName}
                </Typography>
              </div>
            ))}
        </Grid>
      </Grid>

      {lastNo > index ? (
        <div className={classes.border}/>
      ) : null}
    </Grid>
  );
};
